import React from 'react'
import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import { LoaderComponent } from '../components/loading-component/loader-component';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import Img from 'gatsby-image';

const Blog = () => {

    const blogDataResponse = useStaticQuery(graphql`
    query MyQuery {
        allSanityPost {
            nodes {
              id
              _rawExcerpt
              authors {
                author {
                  name
                }
              }
              mainImage {
                asset {
                  url
                  fluid(maxHeight: 100, maxWidth: 400){
                    ...GatsbySanityImageFluid
                    }
                }
              }
              title
              publishedAt
              slug{
                  current
              }
            }
          }
      }
    `);

    const blogData = blogDataResponse.allSanityPost.nodes || [];

    const onBlogPostClick = (node: any): void => {
        navigate(`/blog/${node.slug.current}`);
    }

    console.log(blogData);

    return (
        <Layout>
            <SEO
                title="Blog #qdr_tech"
                description="Blog for http://qdrtech.com. Find interesting tutorials and posts related to the tech community and self-improvement"
                lang="eng"
                twiterTitle="I blog about stuff @qdr_tech"
                twitterCard="Check out my blog posts over at http://qdrtech.com, I try to keep things interesting ;)"
                twitterDescription="Blog for http://qdrtech.com."
                twitterSummary="Find interesting tutorials and posts related to the tech community and self-improvement here." />
            <div className="justify-center flex flex-row grid">
                {blogData ? blogData.map((node: any, index: number) => {
                    return <div key={index} className="container p-4 flex flex-col justify-center shadow-2xl">
                        <div className="flex justify-center mb-2">
                            <p className="text-2xl text-grey-darker font-medium mb-4">{node.title}</p>
                        </div>
                        <div className="flex-1 justify-center mb-2">
                            <Img fluid={node.mainImage.asset.fluid} />
                        </div>
                        <div className="flex-1 justify-center mb-2">
                            <p className="text-sm text-right">
                                Published: {new Date(node.publishedAt).toLocaleDateString()}
                                <br />
                                Author: {node.authors[0].author.name}
                            </p>
                        </div>
                        <div className="flex-1 justify-center mb-2">
                            <div className="p-2 container flex flex-col bg-white rounded-lg text-black">
                                <p className="text-grey mb-6">{node._rawExcerpt[0].children[0].text.substring(0,100)}</p>
                                <button type="button" onClick={() => onBlogPostClick(node)} className="bg-blue hover:bg-blue-dark text-black border border-blue-dark rounded px-6 py-4 justify-center">Read</button>
                            </div>
                        </div>
                    </div>
                })
                    :
                    <LoaderComponent />
                }
            </div>
        </Layout >
    )
}

export default Blog;
